import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ABI_BASE_URL } from './constants/globalConstants';

function Auth() {
  const navigate = useNavigate();
  // const { company } = useParams();
  // const params = useParams();

  function getContentBetweenMarkers(str, endMarker) {
    const startMarker = '/#/';
    const startIndex = str.indexOf(startMarker);
    const endIndex = str.indexOf(endMarker);
    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
      return str.substring(startIndex + startMarker.length, endIndex);
    }
    return null;
  }

  const redirectTo = (loginUrl) => {
    if (sessionStorage.getItem('isABI') === 'true') {
      const eventId = sessionStorage.getItem('classInstanceId') || '';
      window.top.location.replace(`${ABI_BASE_URL}/events/${eventId}`);
    } else if (Cookies.remove('isSpecial') === '1') {
      window.location.reload();
    } else {
      navigate(loginUrl);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // setUserCookie(Cookies.get('user'));
      const currentUrl = window.location.href;
      // const user = Cookies.get('user');
      // const token = Cookies.get('token');
      let loginurl = '/login';
      if (currentUrl?.includes('solo-instructor')) {
        const company = getContentBetweenMarkers(currentUrl, '/solo-instructor/');
        loginurl = `/${company}/solo-instructor/login`;
      }
      if (currentUrl?.includes('solo-client')) {
        const company = getContentBetweenMarkers(currentUrl, '/solo-client/');
        loginurl = `/${company}/solo-client/client/app/login`;
      }
      // console.log('loginURL', 'Nav', loginurl);
      // setLoginURL(loginurl);
      if (Cookies.get('token') && !Cookies.get('user')) {
        Cookies.remove('token');
        Cookies.remove('hosttype');
        localStorage.clear('clientsTabQueryData');
        // location.reload();
        redirectTo(loginurl);
      }
      if (Cookies.get('user') && !Cookies.get('token')) {
        Cookies.remove('user');
        Cookies.remove('hosttype');
        localStorage.clear('clientsTabQueryData');
        redirectTo(loginurl);
      }
    }, 1000); // Check for cookie changes every 1 second

    return () => clearInterval(interval);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  return '';
}

export default Auth;
