import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import NetworkProvider from './contexts/networkContexts';

import AjaxInterceptors from './utils/AjaxInterceptors';
import AppRoute from './route/index';
import Loader from './components/Loader/Loader';
import store from './store/store';
import Auth from './Auth';

function App() {
  return (
    <HashRouter>
      <CssBaseline />
      <Suspense fallback={<Loader />}>
        <NetworkProvider>
          <>
            <Provider store={store}>
              <AjaxInterceptors />
              <AppRoute />
              <Auth />
            </Provider>
          </>
        </NetworkProvider>
      </Suspense>
    </HashRouter>
  );
}

export default App;
